import React from 'react'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import PropTypes from 'prop-types'
import { useLocalize } from 'app/base/componentHelpers'
import { CEBIA_BASE_URL } from 'app/base/Constants'
import SbrInfo from 'app/component/sbrInfo/SbrInfo'

import './CebiaLinkView.less'
import './CebiaLinkCS.json'

const CLASSNAME = 'c-cebia-link'

const CebiaCheckLink = ({ advertEntity }) => {
	const { vin, cebiaSmartCodeUrl, hideVin } = advertEntity

	const localize = useLocalize()

	if (!vin || hideVin) return null

	const url = `${CEBIA_BASE_URL}/?vin=${vin}&utm_source=Karta_vozu_tlacitko&utm_medium=tlacitko&utm_content=Landing_page_provereni&utm_campaign=Sauto_2013`

	const text = !cebiaSmartCodeUrl
		? localize('CebiaLink.historyCheck')
		: localize('CebiaLink.historyCheckFree')

	return (
		<>
			<a
				className={CLASSNAME}
				href={cebiaSmartCodeUrl || url}
				target='_blank'
				rel='nofollow noopener noreferrer'
				data-dot='go-to-check-history'
			>
				{text}
			</a>
			<SbrInfo paid='Cebia, spol. s r.o.' behalf='Cebia, spol. s r.o.' />
		</>
	)
}

CebiaCheckLink.propTypes = {
	advertEntity: PropTypes.shape(advertPropTypes)
}

const CebiaLinkView = ({ advertEntity }) => {
	return (
		<React.Fragment>
			<CebiaCheckLink advertEntity={advertEntity} />
		</React.Fragment>
	)
}

CebiaLinkView.propTypes = {
	advertEntity: PropTypes.shape(advertPropTypes)
}

export default React.memo(CebiaLinkView)
