import React, { useContext, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Context from 'ima/page/context'
import UserEntityContext from 'app/component/managedRootView/UserEntityContext'

import './SbrInfo.less'

const CLASSNAME = 'c-sbr-info'

const SbrInfo = ({ paid = '', behalf = '' }) => {
	const sbrIRef = useRef()
	const context = useContext(Context)
	const sspHelper = context.SspHelper
	const userEntity = useContext(UserEntityContext)

	// priznak, zda si uzivatel plati "Seznam bez reklam" (SBR)
	const isSbrUser = userEntity?.isSbr

	useEffect(() => {
		if (sbrIRef.current && isSbrUser) {
			sspHelper.appendCoopIconToElement(sbrIRef.current, {
				paid,
				behalf,
				align: 'left'
			})
		}
	}, [sspHelper, sbrIRef, isSbrUser, paid, behalf])

	return <span className={CLASSNAME} ref={sbrIRef} />
}

SbrInfo.propTypes = {
	paid: PropTypes.string,
	behalf: PropTypes.string
}

export default React.memo(SbrInfo)
